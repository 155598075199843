import * as React from "react";
import { ButtonRegisterNow } from "./ButtonRegisterNow";
import moment from "moment";
const mFormat = "MMMM Do, YYYY";

export const Availables = (props: any) => {
  const { sections, openApplication, setSectionUuid } = props;

  return (
    <section className="container px-[15px] mx-auto pt-[40px] lg:pt-[60px] pb-[60px]">
      <h3 className="ff-cg--bold text-[30px] lg:text-[40px] leading-none mb-4 lg:mb-8 ">
        Available Dates
      </h3>
      <table className="table-avaliable table-auto w-full bg-gray-custom rounded-3xl">
        <thead>
          <tr>
            <th>start date</th>
            <th>end date</th>
            <th>country</th>
            <th>languages</th>
            <th>type</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sections?.map((item: any, index: number) => {
            return (
              <tr key={index}>
                <td>{moment(new Date(item.startDate)).format(mFormat)}</td>
                <td>{moment(new Date(item.endDate)).format(mFormat)}</td>
                <td>{item.country}</td>
                <td>{item.language}</td>
                <td>{item.type}</td>
                <td>
                  {
                    <ButtonRegisterNow
                      openApplication={openApplication}
                      setSectionUuid={setSectionUuid}
                      sectionUuid={item.uuid}
                    />
                  }
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
};
