import * as React from "react";
import { useEffect } from "react";
import {
  PlayIcon,
  ClockIcon,
  BookOpenIcon,
  CheckCircleIcon,
  DocumentIcon,
  FolderIcon,
} from "@heroicons/react/24/outline";
import { Link } from "gatsby";

export const CDetail = (props: any) => {
  let response: JSX.Element;
  const course = props.courseObj;
  const sponsor = course?.sponsor;
  const { creditsList, modules, children } = props;

  const mindEdge = (
    <>
      <section className="bg-[#da1a32]">
        <div className="container px-[15px] mx-auto pt-[40px] pb-[40px] lg:pt-[40px] lg:pb-[40px]">
          <h3 className="text-white text-[30px] lg:text-[50px] mb-[20px] ff-cg--semibold text-center">
            What’s Included
          </h3>
          <div className="flex lg:grid gap-4 lg:gap-10 lg:grid-cols-12 overflow-x-auto">
            {children &&
              children.map((item: any, index: number) => {
                return (
                  <div
                    className="min-w-[80%] md:min-w-[60%] lg:min-w-fit lg:col-span-3"
                    key={index}
                  >
                    <a href={`/course/${item.slug}`}>
                      <div className="relative">
                        <div className="before:bg-black before:absolute before:top-0 before:bottom-0 before:left-0 before:right-0 before:rounded-3xl before:opacity-50"></div>
                        <img
                          className="object-cover w-full h-[250px] rounded-3xl bg-slate-300"
                          src={item.imgUrl}
                          alt=""
                        />
                      </div>
                      <div className="rounded-3xl bg-white p-[30px] flex flex-col justify-between h-[300px] mt-[-30px] shadow-lg relative">
                        <div>
                          <div className="flex items-center gap-4 mb-[16px]">
                            <span className="flex items-center text-[#da1a32] border border-[#da1a32] rounded-full pl-[3px] pr-[10px]">
                              <ClockIcon className="h-4 w-4 mr-[6px]" />
                              <span className="ff-cg--semibold text-[12px]">
                                Access: {item.access}
                              </span>
                            </span>
                          </div>
                          <h4 className="text-[16px] lg:text-[20px] ff-cg--semibold leading-none mb-[10px]">
                            {item.title}
                          </h4>
                          <p>{item.description}</p>
                        </div>
                        <div className="flex items-center justify-between">
                          <p className="ff-cg--semibold text-[20px]">
                            {Number(item.price).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                              currencyDisplay: "symbol",
                            })}
                          </p>
                          <span className="flex items-center text-[#da1a32] border border-[#da1a32] rounded-full pl-[3px] pr-[10px]">
                            <ClockIcon className="h-4 w-4 mr-[6px]" />
                            <span className="ff-cg--semibold text-[12px]">
                              {item.duration}
                            </span>
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })}
          </div>
        </div>
      </section>

      <section>
        <div className="container p-[30px] mx-auto pt-[40px] lg:pt-[30px] pb-[20px]">
          <div className="bg-[#222222] rounded-2xl col-span-12 mb-[20px]">
            <div className="lg:flex relative lg:justify-between p-[15px] lg:px-[30px] lg:py-[40px]">
              <div className="w-full lg:w-1/3 p-4">
                <h3 className="ff-cg--semibold text-[#fdbf3c] flex items-center text-[12px] lg:text-[14px] leading-none mb-2">
                  <ClockIcon className="h-6 w-6 mr-2" /> ACCESS TIME
                </h3>
                <p className="ff-cg--bold text-white text-base text-[40px] lg:text-[50px] leading-normal mb-8">
                  {course?.access}
                </p>
                <h3 className="ff-cg--semibold text-[#fdbf3c] flex items-center text-[12px] lg:text-[14px] leading-none mb-2">
                  <BookOpenIcon className="h-6 w-6 mr-2" /> CREDITS
                </h3>
                {creditsList &&
                  creditsList.map((item: any, index: number) => (
                    <p
                      className="text-white text-base flex items-center text-[18px] lg:text-[24px] leading-normal"
                      key={index}
                    >
                      <CheckCircleIcon className="h-6 w-6 mr-2" /> {item}
                    </p>
                  ))}
              </div>
              <div className="w-full lg:w-1/3 p-4">
                <h3 className="ff-cg--semibold text-[#fdbf3c] text-[12px] lg:text-[14px] leading-none mb-2">
                  PREREQUISITES
                </h3>
                {course ? (
                  <p className="text-white text-base text-[16px] lg:text-[18px] leading-normal mb-8">
                    {course.detail?.prerequisites}
                  </p>
                ) : (
                  ""
                )}
                <h3 className="ff-cg--semibold text-[#fdbf3c] text-[12px] lg:text-[14px] leading-none mb-2">
                  REFUND POLICY
                </h3>
                <p className="text-white text-base text-[16px] lg:text-[18px] leading-normal">
                  You may request a refound up to 7 days from the purchase date.
                  The registration fee will only be refunded if less than 10% of
                  the course has been completed. Completion percentage can be
                  viewed on the Course Progress page from within the course.
                </p>
              </div>
              <div className="w-full lg:w-1/3 p-4">
                <h3 className="ff-cg--semibold text-[#fdbf3c] text-[12px] lg:text-[14px] leading-none mb-2">
                  ADDITIONAL INFO
                </h3>
                {course ? (
                  <p className="text-white text-base text-[16px] lg:text-[18px] leading-normal mb-4">
                    {course.detail?.additional_info}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );

  const both = (
    <section className="bg-[#222222] mb-[40px]">
      <div className="container px-[15px] lg:px-[60px] mx-auto pt-[30px] pb-[30px] lg:pb-[40px]">
        <h3 className="text-white text-[30px] lg:text-[50px] mb-[20px] ff-cg--semibold text-center">
          What’s Included
        </h3>
        <div className="border solid rounded-2xl">
          {modules &&
            modules.map((item: any, index: number) => {
              return (
                <div
                  className={`flex p-[20px] ${
                    index === modules.length - 1 ? "" : "border-b solid"
                  }`}
                  key={index}
                >
                  <div className="w-full mb-4 lg:mb-0">
                    <h4 className="text-[16px] lg:text-[30px] ff-cg--bold text-white mb-[10px]">
                      {item.attributes.title}
                    </h4>
                    <p className="text-[18px] lg:text-[24px] ff-cg--light text-[#cccccc]">
                      {item.attributes.description}
                    </p>
                  </div>
                  <div className="flex lg:block w-full lg:w-[200px] text-center pt-6">
                    <ClockIcon className="h-[30px] w-[30px] lg:h-[35px] lg:w-[35px] lg:m-auto text-[#fcc136] mr-[10px] lg:mb-[6px]" />
                    <p className="text-[18px] lg:text-[22px] ff-cg--semibold text-[#fcc136]">
                      {item.attributes.quizzes === 2
                        ? "Varies"
                        : item.attributes.quizzes === 0
                        ? item.attributes.resource + " Hours"
                        : item.attributes.quizzes === 1
                        ? item.attributes.resource + " Weeks"
                        : "N/A"}{" "}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );

  switch (sponsor?.title) {
    case "MindEdge":
      response = mindEdge;
      break;
    case "MedCerts":
    case "Springboard":
    case "CertWise":
      response = both;
      break;
    default:
      response = <></>;
  }

  return response;
};
