import * as React from "react";
import { useEffect, useRef, useState, useContext } from "react";
import ModalApplication from "../../components/Modal/Application";
import Layout, { Head } from "../../components/Layout/Layout";
import toast from "react-hot-toast";
import axios from "axios";
import { getCart, createCart, addCourseToCart } from "../../helpers/cart";
import { Footer } from "../../components/Footer/Footer";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import { fetchCart } from "../../reducers/cartSlice";
import { useAppDispatch } from "../../store/hooks";
import { UserInfo } from "../../const";
import { fetchEnrolls } from "../../reducers/enrollSlice";
import { Banner } from "../../components/Courses/Banner";
import { Learn } from "../../components/Courses/Learn";
import { Skill } from "../../components/Courses/Skill";
import { Description } from "../../components/Courses/Description";
import { Why } from "../../components/Courses/Why";
import { AdditionalInfo } from "../../components/Courses/AdditionalInfo";
import { Detail } from "../../components/Courses/Detail";
import { Parent } from "../../components/Courses/Parent";
import { CBanner } from "../../components/Certificates/CBanner";
import { CDetail } from "../../components/Certificates/CDetail";
import { CAdditionalInfo } from "../../components/Certificates/CAdditionalInfo";
import { CWhy } from "../../components/Certificates/CWhy";
import { ICourse } from "../../type";
import { Availables } from "../../components/Availables";
import NotFoundPage from "../404";

const Course = ({ location, params }: any) => {
  const { items }: any = useSelector((state: RootState) => state.loginSlice);
  const userElement: any = useSelector((state: RootState) => state.userSlice);
  const enrollElement: any = useSelector(
    (state: RootState) => state.enrollSlice
  );

  const dispatch = useAppDispatch();
  const headerRef: any = useRef();

  const [modalOpen, setModalOpen] = useState(false);
  const [signed, setSigned] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const [userUuid, setUserUuid] = useState(null);
  const [sponsor, setSponsor] = useState<any>(null);
  const [courseObj, setCourseObj] = useState<ICourse>();
  const [courseUuid, setCourseUuid] = useState(null);
  // const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);
  const [access, setAccess] = useState("");
  const [learns, setLearns] = useState<any>(null);
  const [creditsList, setCreditsList] = useState<any>(null);
  const [modules, setModules] = useState<any>(null);
  const [children, setChildren] = useState<any>(null);
  const [parent, setParent] = useState<any>(null);

  const [allowed, setAllow] = useState<boolean>(false);
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const [openCart, setOpenCart] = useState<boolean>(false);
  const [slugTerm, setSlugTerm] = useState<string>("");
  const [sectionUuid, setSectionUuid] = useState(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const slug: string = location.pathname.replace("/course/", "");
    axios
      .get(`${process.env.API_URL}/api/course/slug/${slug}`)
      .then((response) => {
        if (response.data.message === "Not found course") {
          setIsError(true);
        }
        const {
          data: { data },
        } = response;
        const learnsArray: any = [];
        const creditArray: any = [];

        setCourseObj(data);
        setCourseUuid(data.uuid);
        setSponsor(data.sponsor);
        setChildren(data.children);
        setModules(data.detail.modules.data);
        if (data.father.length) setParent(data.father[0]);

        if (data.detail.learns) {
          const learnString = data.detail.learns;
          learnString.split("|").map((item: string) => {
            learnsArray.push(item.trim());
          });
          setLearns(learnsArray);
        }

        if (data.detail.credits_list !== null) {
          const creditString = data.detail.credits_list;
          creditString.split("|").map((item: string) => {
            creditArray.push(item.trim());
          });
          setCreditsList(creditArray);
        }
      })
      .catch((error) => {
        console.log("**** error from user **** ", error);
      });
  }, []);

  const fetchCurrent = async (token: string) => {
    await dispatch(fetchCart(token));
    await dispatch(fetchEnrolls(token));
  };

  const handleModal = () => {
    setModalOpen(!modalOpen);
    if (modalOpen) document.body.classList.remove("overflow-hidden");
  };

  const openApplication = () => {
    setModalOpen(true);
    document.body.classList.add("overflow-hidden");
  };

  const addToCart = async (item: any) => {
    setSubmitLoader(true);

    let itemNew = { ...item };
    itemNew.course = { ...item };

    if (signed) {
      let cartIsOn = false;
      await getCartClient().then((response) => {
        cartIsOn = response.status;
      });
      if (!cartIsOn) {
        await createCart([{ uuid: item.uuid, price: parseFloat(item.price) }]);
        await addCourseToCart({
          uuid: item.uuid,
          price: parseFloat(item.price),
        });
        await fetchCurrent(items.access_token);
        setSubmitLoader(false);
        setOpenCart(true);
      } else {
        await addCourseToCart({
          uuid: item.uuid,
          price: parseFloat(item.price),
        });
        await fetchCurrent(items.access_token);
        setSubmitLoader(false);
        setOpenCart(true);
      }
    } else {
      let cartItems = [];
      let cartOn =
        typeof window !== "undefined" && localStorage.getItem("cart");
      if (cartOn !== null) {
        cartItems = JSON.parse(cartOn.toString());
      }
      const filtered = cartItems.filter(
        (cartItem: any) => cartItem.uuid === item.uuid
      );
      if (!filtered.length) {
        cartItems.push(itemNew);
      } else {
        toast.error("This item already is on your cart");
      }
      typeof window !== "undefined" &&
        localStorage.setItem("cart", JSON.stringify(cartItems));
      headerRef.current?.setCoursesCircle();
      setSubmitLoader(false);
      setOpenCart(true);
    }
  };

  const getCartClient = async () => {
    const gotCart = await getCart();
    return gotCart;
  };

  const handleCart = (value: any) => {
    setOpenCart(value);
  };

  useEffect(() => {
    items.status ? setSigned(true) : setAllow(true);
  }, [items]);

  useEffect(() => {
    if (userElement.items.data !== undefined) {
      setUserUuid(userElement.items.data.id);
      setIsAdmin(
        userElement.items.data.profile.userType === UserInfo.USER_ADMIN_GROUP
      );
    }
  }, [userElement]);

  useEffect(() => {
    if (courseUuid) {
      if (enrollElement) {
        if (
          enrollElement.items.data !== null &&
          enrollElement.items.data !== undefined &&
          enrollElement.items.data.length
        ) {
          setAllow(
            !enrollElement.items.data.some(
              (item: any) =>
                (item.course.uuid ? item.course.uuid.toString() : "") ===
                courseUuid
            )
          );
        } else {
          setAllow(true);
        }
      } else {
        console.log("found nothing");
        setAllow(true);
      }
    }
  }, [courseUuid]);

  if (isError) return <NotFoundPage />;
  return (
    <Layout
      signed={signed}
      handleCart={handleCart}
      openCart={openCart}
      isFixed={
        courseObj?.type.name === "Course"
          ? sponsor?.title === "MindEdge" &&
            sponsor?.title == "SHRM" &&
            sponsor?.title == "ASCM" &&
            sponsor?.title == "ATD"
          : true
      }
      ref={headerRef}
    >
      <div className="bg-slate-50">
        {courseObj?.type.name === "Course" ? (
          <Banner
            values={{ courseObj, allowed, isAdmin, submitLoader }}
            functions={{ addToCart, openApplication }}
          />
        ) : (
          <CBanner
            values={{ courseObj, allowed, isAdmin, submitLoader }}
            functions={{ addToCart, openApplication }}
          />
        )}

        <section className="container px-[15px] mx-auto pt-[40px] lg:pt-[60px] pb-[60px]">
          <div className="lg:grid gap-4 lg:gap-10 lg:grid-cols-12">
            <Learn learns={learns} />

            <Skill skills={courseObj?.skills} />

            <Description course={courseObj} />
          </div>
        </section>

        {courseObj?.sections?.length && (
          <Availables
            sections={courseObj?.sections}
            openApplication={openApplication}
            setSectionUuid={setSectionUuid}
          />
        )}

        {courseObj?.type.name === "Course" ? (
          <Detail
            courseObj={courseObj}
            modules={modules}
            creditsList={creditsList}
          />
        ) : (
          <CDetail
            courseObj={courseObj}
            modules={modules}
            creditsList={creditsList}
            children={children}
          />
        )}

        {courseObj?.type.name === "Course" ? (
          <AdditionalInfo title={sponsor?.title} courseObj={courseObj} />
        ) : (
          <CAdditionalInfo title={sponsor?.title} courseObj={courseObj} />
        )}

        {courseObj?.type.name === "Course" ? (
          <Why title={sponsor?.title} />
        ) : (
          <CWhy title={sponsor?.title} />
        )}

        {parent && <Parent parent={parent} />}
        <Footer />
      </div>

      {modalOpen && (
        <ModalApplication
          handleModal={handleModal}
          userUuid={userUuid}
          cursoId={courseObj?.title}
          courseUuid={courseObj?.uuid}
          sponsor={sponsor?.title}
          sectionUuid={sectionUuid}
        />
      )}
    </Layout>
  );
};

export default Course;

export { Head } from "../../components/Layout/Head";
