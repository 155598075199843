import React from "react";
import { CheckIcon } from "@heroicons/react/24/outline";

export const Learn = (props) => {
  const learns = props.learns;
  return (
    <div className="lg:col-span-8">
      <div className="text-center md:text-left">
        <h3 className="text-[30px] mb-[20px] ff-cg--semibold">
          What You Will Learn
        </h3>
      </div>
      <div className="lg:grid gap-4 lg:gap-10 lg:grid-cols-12 mb-[20px] lg:mb-0">
        {learns &&
          learns.map((item: any, index: number) => {
            return (
              <div className="lg:col-span-6 mb-[20px] lg:mb-0" key={index}>
                <div className="flex items-center">
                  <div className="flex items-center justify-center bg-[#da1a32] rounded-full p-[2px] mr-[10px]">
                    <CheckIcon className="h-6 w-6 text-white" />
                  </div>
                  <p className="leading-none">{item}</p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
