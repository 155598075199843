import React from "react";

export const Description = ({ course }: { course: any }) => {
  return (
    <div className="lg:col-span-12  px-[15px] pt-[40px] mt-[30px]">
      {course && course.detail && (
        <>
          <h3 className="ff-cg--bold text-[30px] lg:text-[40px] leading-none mb-4 lg:mb-6">
            {course?.detail?.highlight}
          </h3>
          <p className="text-base text-[18px] lg:text-[26px] leading-normal">
            {course?.detail?.description}
          </p>
        </>
      )}
    </div>
  );
};
