
import * as React from "react";

export const ButtonRegisterNow = (props) => {
  const { openApplication, setSectionUuid, sectionUuid } = props;
  return (
    <button
      className="rounded-3xl bg-yellow-custom border-none px-6 py-2 capitalize font-bold"
      onClick={() => {
        setSectionUuid(sectionUuid);
        openApplication();
      }}
    >
      register now
    </button>
  );
};
