import axios from "axios";
import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { navigate } from "gatsby";
import { Formik, useFormik } from "formik";
import { Loader } from "../Loader/Loader";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/hooks";
import { createCart, getCart, addCourseToCart } from "../../helpers/cart";
import { loginSelector, fetchLogin } from "../../reducers/loginSlice";
import { RootState } from "../../reducers/rootReducer";
import { fetchItems } from "../../reducers/indexItemsSlice";
import { fetchCart } from "../../reducers/cartSlice";
import { fetchUser } from "../../reducers/userSlice";
import { UserInfo } from "../../const";
import toast from "react-hot-toast";
import { MuiTelInput } from "mui-tel-input";
import StrongPassword from "../StrongPassword/StrongPassword";
import { useVisitorApi } from "../../hooks/useVisitorApi";
import { ButtonCloseModal } from "../ButtonCloseModal";

const ModalApplication = ({
  handleModal,
  setCoursesCircle,
  userUuid,
  courseUuid,
  sponsor,
  cursoId,
  sectionUuid,
}: any) => {
  const dispatch = useAppDispatch();

  const loginElement: any = useSelector((state: RootState) => state.loginSlice);

  const [isApplication, setApplication] = useState<boolean>(true);
  const [isLogIn, setLogIn] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>(false);
  const [compare, setCompare] = useState<any>(null);
  const [errorLogin, setErrorLogin] = useState<boolean>(false);
  const [errorRegister, setErrorRegister] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [validPassword, setValidPassword] = useState<boolean>(false);
  const [token, setToken] = useState("");
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(true);
  const [sendMessageCut, setSendMessage] = useState(false);
  const [isShowViewSpecialApplication, setIsShowViewSpecialApplication] =
    useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const { countryCode } = useVisitorApi();
  const isTrueShowSpecialApplication = () => {
    const isTrue =
      process.env.SPECIAL_APPLICATION_1 === courseUuid ||
      process.env.SPECIAL_APPLICATION_2 === courseUuid ||
      process.env.SPECIAL_APPLICATION_COURSE === courseUuid;
    setIsShowViewSpecialApplication(isTrue);
  };
  useEffect(() => {
    // console.log("entra getuser");
    getUser();
    isTrueShowSpecialApplication();
  }, [userUuid]);

  const getUser = async () => {
    await axios
      .get(process.env.API_URL + "/api/users/" + userUuid)
      .then((response) => {
        let user = response.data.data.profile;
        user.id = response.data.data.id;
        user.status = response.data.data.status;
        setUserData(user);
        setValidPassword(true);
        setLoader(false);
        //setLogIn(true);
      })
      .catch((error) => {
        console.log("**** error from user **** ", error);
        setLoader(false);
      });
  };

  const formikApp = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: userData ? userData.firstName + " " + userData.lastName : "",
      email: userData ? userData.email : "",
      phone: userData ? userData.mobilePhone : "",
      password: userData ? "123456" : "",
      courseUuid,
      sectionUuid,
      paymentMethod: isShowViewSpecialApplication ? "ASIA" : "",
      preferredStart: "",
      acceptRegister: false,
      acceptTerms: false,
    },
    validate: (values) => {
      const errors: {
        name?: string;
        email?: string;
        email2?: string;
        phone?: string;
        courseUuid?: string;
        paymentMethod?: string;
        preferredStart?: string;
        acceptRegister?: string;
        acceptTerms?: string;
        password?: string;
      } = {};

      if (!values.name || !(values.name.indexOf(" ") >= 0)) {
        errors.name = "Required";
      }
      if (!values.email) {
        errors.email = "Required";
      }
      if (
        values.email &&
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)
      ) {
        errors.email = "Required";
      }
      if (!values.phone) {
        errors.phone = "Required";
      }
      if (!values.courseUuid) {
        errors.courseUuid = "Required";
      }
      if (values.acceptRegister) {
        if (!values.password || !validPassword) {
          errors.password = "Required";
        }
      }
      if (!values.acceptTerms) {
        errors.acceptTerms = "Required";
      }
      if (!values.paymentMethod) {
        errors.paymentMethod = "Required";
      }
      if (!values.preferredStart && !isShowViewSpecialApplication) {
        errors.preferredStart = "Required";
      }
      return errors;
    },
    validateOnChange: false,
    onSubmit: async (values: any) => {
      setSubmitLoader(true);
      let fullname = values.name;
      if (!userData) {
        values.firstName = fullname.split(" ").slice(0, -1).join(" ")
          ? fullname.split(" ").slice(0, -1).join(" ")
          : fullname;
        values.lastName =
          fullname.indexOf(" ") >= 0
            ? fullname.split(" ").slice(-1).join(" ")
            : "";
        if (showPassword) {
          await createUser(
            values.email,
            values.firstName,
            values.lastName,
            values.phone,
            values.password
          );
          await loginUser(values.email, values.password);
        }
      }

      if (validPassword) {
        await createApplication(values);
      } else {
        await createApplicationFree(values);
      }

      setSubmitLoader(false);
    },
  });

  useEffect(() => {
    setShowPassword(formikApp.values.acceptRegister);
  }, [formikApp.values.acceptRegister]);

  const formikLogin = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
      password: "",
    },
    validate: (values: any) => {
      const errors: { username?: string; password?: string } = {};

      if (!values.username) {
        errors.username = "Required";
      }

      if (!values.password) {
        errors.password = "Required";
      }

      return errors;
    },
    validateOnChange: false,
    onSubmit: async (values: any) => {
      setSubmitLoader(true);
      await loginUser(values.username, values.password);
      setSubmitLoader(false);
    },
  });

  const handlePassword = (data: boolean) => {
    setValidPassword(data);
  };

  useEffect(() => {
    if (compare) {
      if (
        compare == UserInfo.USER_ADMIN_GROUP ||
        compare == UserInfo.USER_ID ||
        compare == UserInfo.USER_GROUP
      ) {
        handleModal();
        setErrorLogin(false);
        if (typeof window !== "undefined" && localStorage.getItem("cart")) {
          const coursesToSend: any = [];
          const cart =
            typeof window !== "undefined" &&
            JSON.parse(localStorage.getItem("cart") || "{}");
          cart.map((item: any) => {
            const itemToPush = {
              uuid: item.uuid,
              price: parseFloat(item.price),
            };
            coursesToSend.push(itemToPush);
          });
          firstCart(coursesToSend);
          typeof window !== "undefined" && localStorage.removeItem("cart");
        }
        setCoursesCircle();
        const comesFromcart =
          typeof window !== "undefined" && localStorage.getItem("fromCart");
      } else {
        setErrorLogin(true);
      }
    }
  }, [compare]);

  const compareCodes = async (code: string) => {
    if (
      code == UserInfo.USER_ADMIN_GROUP ||
      code == UserInfo.USER_ID ||
      code == UserInfo.USER_GROUP
    ) {
      setErrorLogin(false);
      setErrorMessage(null);
      setSendMessage(false);
      if (typeof window !== "undefined" && localStorage.getItem("cart")) {
        const coursesToSend: any = [];
        const cart =
          typeof window !== "undefined" &&
          JSON.parse(localStorage.getItem("cart") || "{}");
        cart.map((item: any) => {
          const itemToPush = { uuid: item.uuid, price: parseFloat(item.price) };
          coursesToSend.push(itemToPush);
        });
        await createTheCart();
        await firstCart(coursesToSend);
        typeof window !== "undefined" && localStorage.removeItem("cart");
      }
      const comesFromcart =
        typeof window !== "undefined" && localStorage.getItem("fromCart");
      if (code == UserInfo.USER_ADMIN_GROUP) {
        typeof window !== "undefined" &&
          localStorage.setItem("isAdmin", "true");
      }
    } else {
      setErrorLogin(true);
    }
  };

  const createApplication = async (values: any) => {
    const token =
      typeof window !== "undefined" && localStorage.getItem("access_token");
    await axios({
      method: "post",
      url: process.env.API_URL + "/api/applications",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(values),
    })
      .then(function (response) {
        if (response.data.status) {
          toast.success("Successfully applied");
          document.body.classList.remove("overflow-hidden");
          return navigate("/course/success");
        } else {
          toast.error(
            "Something went wrong, try again.\nSystem message: " +
              response.data.message
          );
          console.log("error toast 1");
        }
      })
      .catch(function (error) {
        toast.error(
          "Something went wrong, try again.\nSystem message: " + error.message
        );
        console.log("error toast 2");
      });
  };

  const createApplicationFree = async (values: any) => {
    await axios({
      method: "post",
      url: process.env.API_URL + "/api/applications/free",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(values),
    })
      .then(function (response) {
        if (response.data.status) {
          toast.success("Successfully applied");
          document.body.classList.remove("overflow-hidden");
          return navigate("/course/success");
        } else {
          toast.error(
            "Something went wrong, try again.\nSystem message: " +
              response.data.message
          );
          console.log("error toast 3");
        }
      })
      .catch(function (error) {
        toast.error(
          "Something went wrong, try again.\nSystem message: " + error.message
        );
        console.log("error toast 4");
      });
  };

  const createTheCart = async () => {
    await createCart([]);
  };

  useEffect(() => {
    if (loginElement.message) {
      console.log(loginElement.message);
      toast.error("This item already is on your cart");
      console.log("error toast 5");
    }
    if (loginElement.items.status) {
      typeof window !== "undefined" &&
        localStorage.setItem("access_token", loginElement.items.access_token);
      const decoded: any = jwt_decode(loginElement.items.access_token);
      fetchUsers(decoded.userId);
      fetchCurrent(loginElement.items.access_token);
      compareCodes(decoded.role);
      setToken(loginElement.items.access_token);
      document.body.classList.remove("overflow-hidden");
    }
    setErrorMessage(loginElement.message);
  }, [loginElement]);

  useEffect(() => {
    if (sendMessageCut) {
      console.log("got the message: **** ", loginElement);
      if (loginElement.message !== "") {
        toast.error(loginElement.message);
      }
      console.log("error toast 6");
    }
  }, [errorMessage]);

  const fetchCurrent = async (token: string) => {
    await dispatch(fetchCart(token));
  };

  const fetchSpecials = async (uuids: any) => {
    await dispatch(fetchItems(uuids));
  };

  const loginUser = async (user: string, password: string) => {
    setErrorLogin(false);
    await dispatch(fetchLogin(user, password));
    setLogIn(!isLogIn);
    setApplication(!isApplication);
    setSendMessage(true);
    setLoader(true);
  };

  const fetchUsers = async (userId: string) => {
    await dispatch(fetchUser(userId));
  };

  // redux

  const changeSignIn = () => {
    setLogIn(!isLogIn);
    setApplication(!isApplication);
  };

  const firstCart = async (coursesToSend: any) => {
    if (coursesToSend.length > 0) {
      coursesToSend.map(async (item: any) => {
        await addCourseToCart(item);
      });
    }
  };

  useEffect(() => {
    setErrorLogin(false);
    setErrorMessage(null);
    setErrorRegister(false);
    setSendMessage(false);
  }, []);

  const createUser = async (
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    password: string
  ) => {
    await axios
      .post(process.env.API_URL + "/api/users", {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        mobilePhone: phoneNumber,
        profileUrl: "",
        group: "Group 1",
        timeZone: "UTC-5",
      })
      .then((response) => {
        toast.success("Successfully registered");
      })
      .catch(function (error) {
        toast.error(error.message);
        console.log("error toast 7");
        console.log("[DEBUG]", error);
        setLoader(false);
      });
  };

  return (
    <div className="fixed p-2 xl:p-10 left-0 top-0 bottom-0 right-0 z-[101] flex overflow-x-hidden overflow-y-auto items-start justify-center">
      <div
        className="bg-opacity-40 bg-black z-10 fixed left-0 top-0 h-full w-full"
        onClick={handleModal}
      ></div>
      <div className="bg-white rounded-3xl overflow-hidden w-[750px] p-6 pt-[60px] lg:pb-[60px] relative z-50">
        <ButtonCloseModal handleModal={handleModal} />
        {/* Sign In */}
        {isApplication
          ? !loader && (
              <div className="">
                <p className="text-center ff-cg--semibold">{cursoId}</p>
                <h3 className="text-[26px] lg:text-[30px] ff-cg--semibold text-center mb-0">
                  Start your application
                </h3>
                <div className="text-center mb-1">
                  {!userData && (
                    <p>
                      Already have an account?{" "}
                      <a
                        href="#"
                        className="ff-cg--semibold text-[#da1a32] ml-2 underline"
                        onClick={(e) => {
                          e.preventDefault();
                          changeSignIn();
                        }}
                      >
                        Sign In for 1 Click Apply
                      </a>
                    </p>
                  )}
                </div>
                <form
                  onSubmit={formikApp.handleSubmit}
                  className="flex items-center justify-center"
                >
                  <div className="w-full lg:w-[80%]">
                    {!userData && (
                      <>
                        <div className="mb-6">
                          <label className="text-sm ff-cg--semibold" htmlFor="">
                            Full Name
                          </label>
                          <input
                            className={
                              "w-full bg-gray-100 placeholder:text-[#000000] p-[10px] focus:outline-none rounded-xl mt-2" +
                              (formikApp.errors.name == "Required"
                                ? " border border-red-500"
                                : "")
                            }
                            type="text"
                            name="name"
                            value={formikApp.values.name}
                            onChange={formikApp.handleChange}
                            placeholder="Your Full Name"
                          />
                          {formikApp.errors.name == "Required" && (
                            <p className="ff-cg--semibold text-red-500 text-[14px] mt-2 flex items-center">
                              <ExclamationTriangleIcon className="w-4 h-4 mr-1" />{" "}
                              You must enter your full name: first name and last
                              name
                            </p>
                          )}
                        </div>
                        <div className="mb-6">
                          <label className="text-sm ff-cg--semibold" htmlFor="">
                            Email
                          </label>
                          <input
                            className={
                              "w-full bg-gray-100 placeholder:text-[#000000] p-[10px] focus:outline-none rounded-xl mt-2" +
                              (formikApp.errors.email == "Required" ||
                              formikApp.errors.email == "Required"
                                ? " border border-red-500"
                                : "")
                            }
                            type="text"
                            name="email"
                            value={formikApp.values.email}
                            onChange={formikApp.handleChange}
                            placeholder="Student@domain.com"
                          />
                          {formikApp.errors.email == "Required" &&
                            formikApp.values.email === "" && (
                              <p className="ff-cg--semibold text-red-500 text-[14px] mt-2 flex items-center">
                                <ExclamationTriangleIcon className="w-4 h-4 mr-1" />{" "}
                                Email address is required
                              </p>
                            )}
                          {formikApp.errors.email == "Required" &&
                            formikApp.values.email !== "" && (
                              <p className="ff-cg--semibold text-red-500 text-[14px] mt-2 flex items-center">
                                <ExclamationTriangleIcon className="w-4 h-4 mr-1" />{" "}
                                Invalid email address
                              </p>
                            )}
                        </div>
                        <div className="mb-6">
                          <label className="text-sm ff-cg--semibold" htmlFor="">
                            Mobile Phone
                          </label>
                          <MuiTelInput
                            className={
                              "w-full bg-gray-100 placeholder:text-[#000000] focus:outline-none !rounded-xl mt-2" +
                              (formikApp.errors.phone == "Required"
                                ? " !border-solid !border !border-red-500"
                                : "")
                            }
                            name="phone"
                            defaultCountry={countryCode ? countryCode : "US"}
                            onChange={(value) =>
                              formikApp.setFieldValue("phone", value)
                            }
                            value={formikApp.values.phone}
                          />
                          {formikApp.errors.phone == "Required" && (
                            <p className="ff-cg--semibold text-red-500 text-[14px] mt-2 flex items-center">
                              <ExclamationTriangleIcon className="w-4 h-4 mr-1" />{" "}
                              Mobile phone is required
                            </p>
                          )}
                        </div>
                      </>
                    )}
                    {isShowViewSpecialApplication && (
                      <div className="mb-6">
                        <label className="text-sm ff-cg--semibold" htmlFor="">
                          Select your Location
                        </label>
                        <div
                          className={
                            "rounded-[30px]  p-2 md:p-[15px] bg-gray-100 mt-2" +
                            (formikApp.errors.paymentMethod == "Required"
                              ? " border border-red-500"
                              : "")
                          }
                        >
                          <div className="flex pl-2 lg:pl-0 flex-col lg:flex-row items-start">
                            <div className="flex items-center mr-4 radio-input">
                              <input
                                id="ASIA"
                                type="radio"
                                value="ASIA"
                                name="paymentMethod"
                                checked={
                                  formikApp.values.paymentMethod == "ASIA"
                                }
                                onChange={formikApp.handleChange}
                                // className="w-[15px] h-[15px] text-dark bg-gray-100 border-dark focus:ring-dark focus:ring-2 "
                              />
                              <label
                                htmlFor="ASIA"
                                className="ml-2 text-base text-dark ff-cg--semibold flex"
                              >
                                <span id="checkbox-img">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <g
                                      stroke-width="0"
                                      id="SVGRepo_bgCarrier"
                                    ></g>
                                    <g
                                      stroke-linejoin="round"
                                      stroke-linecap="round"
                                      id="SVGRepo_tracerCarrier"
                                    ></g>
                                    <g id="SVGRepo_iconCarrier">
                                      {" "}
                                      <g id="Interface / Check">
                                        {" "}
                                        <path
                                          stroke-linejoin="round"
                                          stroke-linecap="round"
                                          stroke-width="2"
                                          stroke="#ffffff"
                                          d="M6 12L10.2426 16.2426L18.727 7.75732"
                                          id="Vector"
                                        ></path>{" "}
                                      </g>{" "}
                                    </g>
                                  </svg>
                                </span>
                                <span className="flex items-center">
                                  <span className="ml-2 ff-cg--semibold">
                                    ASIA
                                  </span>
                                </span>
                              </label>
                            </div>
                            <div className="flex items-center mr-4 radio-input">
                              <input
                                id="EUROPE"
                                type="radio"
                                value="EUROPE"
                                name="paymentMethod"
                                checked={
                                  formikApp.values.paymentMethod == "EUROPE"
                                }
                                onChange={formikApp.handleChange}
                                className="w-[15px] h-[15px] text-dark bg-gray-100 border-dark focus:ring-dark focus:ring-2"
                              />
                              <label
                                htmlFor="EUROPE"
                                className="ml-2 text-base text-dark ff-cg--semibold flex"
                              >
                                <span id="checkbox-img">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <g
                                      strokeWidth="0"
                                      id="SVGRepo_bgCarrier"
                                    ></g>
                                    <g
                                      stroke-linejoin="round"
                                      stroke-linecap="round"
                                      id="SVGRepo_tracerCarrier"
                                    ></g>
                                    <g id="SVGRepo_iconCarrier">
                                      {" "}
                                      <g id="Interface / Check">
                                        {" "}
                                        <path
                                          stroke-linejoin="round"
                                          stroke-linecap="round"
                                          strokeWidth="2"
                                          stroke="#ffffff"
                                          d="M6 12L10.2426 16.2426L18.727 7.75732"
                                          id="Vector"
                                        ></path>{" "}
                                      </g>{" "}
                                    </g>
                                  </svg>
                                </span>
                                <span className="flex items-center">
                                  <span className="ml-2 ff-cg--semibold">
                                    EUROPE
                                  </span>
                                </span>
                              </label>
                            </div>
                            <div className="flex items-center mr-4 radio-input">
                              <input
                                id="STATESIDE"
                                type="radio"
                                value="STATESIDE"
                                name="paymentMethod"
                                checked={
                                  formikApp.values.paymentMethod == "STATESIDE"
                                }
                                onChange={formikApp.handleChange}
                                className="w-[15px] h-[15px] text-dark bg-gray-100 border-dark focus:ring-dark focus:ring-2"
                              />
                              <label
                                htmlFor="STATESIDE"
                                className="ml-2 text-base text-dark ff-cg--semibold flex"
                              >
                                <span id="checkbox-img">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <g
                                      strokeWidth="0"
                                      id="SVGRepo_bgCarrier"
                                    ></g>
                                    <g
                                      stroke-linejoin="round"
                                      stroke-linecap="round"
                                      id="SVGRepo_tracerCarrier"
                                    ></g>
                                    <g id="SVGRepo_iconCarrier">
                                      {" "}
                                      <g id="Interface / Check">
                                        {" "}
                                        <path
                                          stroke-linejoin="round"
                                          stroke-linecap="round"
                                          strokeWidth="2"
                                          stroke="#ffffff"
                                          d="M6 12L10.2426 16.2426L18.727 7.75732"
                                          id="Vector"
                                        ></path>{" "}
                                      </g>{" "}
                                    </g>
                                  </svg>
                                </span>
                                <span className="flex items-center">
                                  <span className="ml-2 ff-cg--semibold">
                                    STATESIDE
                                  </span>
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        {formikApp.errors.paymentMethod == "Required" && (
                          <p className="ff-cg--semibold text-red-500 text-[14px] mt-2 flex items-center">
                            <ExclamationTriangleIcon className="w-4 h-4 mr-1" />{" "}
                            Preferred Method of Payment is required
                          </p>
                        )}
                      </div>
                    )}
                    {!isShowViewSpecialApplication && (
                      <div className="mb-6">
                        <label className="text-sm ff-cg--semibold" htmlFor="">
                          Preferred Method of Payment
                        </label>
                        <div
                          className={
                            "rounded-[30px] bg-white p-2 md:p-[15px] bg-gray-100 mt-2" +
                            (formikApp.errors.paymentMethod == "Required"
                              ? " border border-red-500"
                              : "")
                          }
                        >
                          <div className="flex pl-2 lg:pl-0 flex-col lg:flex-row items-start">
                            <div className="flex items-center mr-4">
                              <input
                                id="default-radio-1"
                                type="radio"
                                value="upfront"
                                name="paymentMethod"
                                checked={
                                  formikApp.values.paymentMethod == "upfront"
                                }
                                onChange={formikApp.handleChange}
                                className="w-[15px] h-[15px] text-dark bg-gray-100 border-dark focus:ring-dark focus:ring-2"
                              />
                              <label
                                form="default-radio-1"
                                className="ml-2 text-base text-dark ff-cg--semibold"
                              >
                                <span className="flex items-center">
                                  <span className="ff-cg--semibold">
                                    Upfront
                                  </span>
                                </span>
                              </label>
                            </div>
                            <div className="flex items-center mr-4">
                              <input
                                id="default-radio-1"
                                type="radio"
                                value="monthly"
                                name="paymentMethod"
                                checked={
                                  formikApp.values.paymentMethod == "monthly"
                                }
                                onChange={formikApp.handleChange}
                                className="w-[15px] h-[15px] text-dark bg-gray-100 border-dark focus:ring-dark focus:ring-2"
                              />
                              <label
                                form="default-radio-1"
                                className="ml-2 text-base text-dark ff-cg--semibold"
                              >
                                <span className="flex items-center">
                                  <span className="ff-cg--semibold">
                                    Monthly
                                  </span>
                                </span>
                              </label>
                            </div>
                            <div className="flex items-center mr-4">
                              <input
                                id="default-radio-1"
                                type="radio"
                                value="financed tutition"
                                name="paymentMethod"
                                checked={
                                  formikApp.values.paymentMethod ==
                                  "financed tutition"
                                }
                                onChange={formikApp.handleChange}
                                className="w-[15px] h-[15px] text-dark bg-gray-100 border-dark focus:ring-dark focus:ring-2"
                              />
                              <label
                                form="default-radio-1"
                                className="ml-2 text-base text-dark ff-cg--semibold"
                              >
                                <span className="flex items-center">
                                  <span className="ff-cg--semibold">
                                    Financed Tutition
                                  </span>
                                </span>
                              </label>
                            </div>
                            <div className="flex items-center mr-4">
                              <input
                                id="default-radio-1"
                                type="radio"
                                value="not sure yet"
                                name="paymentMethod"
                                checked={
                                  formikApp.values.paymentMethod ==
                                  "not sure yet"
                                }
                                onChange={formikApp.handleChange}
                                className="w-[15px] h-[15px] text-dark bg-gray-100 border-dark focus:ring-dark focus:ring-2"
                              />
                              <label
                                form="default-radio-1"
                                className="ml-2 text-base text-dark ff-cg--semibold"
                              >
                                <span className="flex items-center">
                                  <span className="ff-cg--semibold">
                                    Not Sure Yet
                                  </span>
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        {formikApp.errors.paymentMethod == "Required" && (
                          <p className="ff-cg--semibold text-red-500 text-[14px] mt-2 flex items-center">
                            <ExclamationTriangleIcon className="w-4 h-4 mr-1" />{" "}
                            Preferred Method of Payment is required
                          </p>
                        )}
                      </div>
                    )}
                    {!isShowViewSpecialApplication && (
                      <div className="mb-6">
                        <label className="text-sm ff-cg--semibold" htmlFor="">
                          Preferred Start Date
                        </label>
                        <div
                          className={
                            "rounded-[30px] bg-white p-2 md:p-[15px] bg-gray-100 mt-2" +
                            (formikApp.errors.preferredStart == "Required"
                              ? " border border-red-500"
                              : "")
                          }
                        >
                          <div className="flex pl-2 lg:pl-0 flex-col lg:flex-row items-start">
                            <div className="flex items-center mr-4">
                              <input
                                id="default-radio-1"
                                type="radio"
                                value="asap"
                                name="preferredStart"
                                checked={
                                  formikApp.values.preferredStart == "asap"
                                }
                                onChange={formikApp.handleChange}
                                className="w-[15px] h-[15px] text-dark bg-gray-100 border-dark focus:ring-dark focus:ring-2"
                              />
                              <label
                                form="default-radio-1"
                                className="ml-2 text-base text-dark ff-cg--semibold"
                              >
                                <span className="flex items-center">
                                  <span className="ff-cg--semibold">ASAP</span>
                                </span>
                              </label>
                            </div>
                            <div className="flex items-center mr-4">
                              <input
                                id="default-radio-1"
                                type="radio"
                                value="within 3 months"
                                name="preferredStart"
                                checked={
                                  formikApp.values.preferredStart ==
                                  "within 3 months"
                                }
                                onChange={formikApp.handleChange}
                                className="w-[15px] h-[15px] text-dark bg-gray-100 border-dark focus:ring-dark focus:ring-2"
                              />
                              <label
                                form="default-radio-1"
                                className="ml-2 text-base text-dark ff-cg--semibold"
                              >
                                <span className="flex items-center">
                                  <span className="ff-cg--semibold">
                                    Within 3 Months
                                  </span>
                                </span>
                              </label>
                            </div>
                            <div className="flex items-center mr-4">
                              <input
                                id="default-radio-1"
                                type="radio"
                                value="not sure yet"
                                name="preferredStart"
                                checked={
                                  formikApp.values.preferredStart ==
                                  "not sure yet"
                                }
                                onChange={formikApp.handleChange}
                                className="w-[15px] h-[15px] text-dark bg-gray-100 border-dark focus:ring-dark focus:ring-2"
                              />
                              <label
                                form="default-radio-1"
                                className="ml-2 text-base text-dark ff-cg--semibold"
                              >
                                <span className="flex items-center">
                                  <span className="ff-cg--semibold">
                                    Not Sure Yet
                                  </span>
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        {formikApp.errors.preferredStart == "Required" && (
                          <p className="ff-cg--semibold text-red-500 text-[14px] mt-2 flex items-center">
                            <ExclamationTriangleIcon className="w-4 h-4 mr-1" />{" "}
                            Preferred Start Date is required
                          </p>
                        )}
                      </div>
                    )}
                    {!userData && (
                      <div className="bg-white rounded-[10px] py-4 px-4 shadow">
                        <div
                          className={`flex items-center  ${
                            formikApp.values.acceptRegister ? "mb-5" : ""
                          } `}
                        >
                          <label
                            htmlFor="acceptRegister"
                            className="ml-4 checkbox-application"
                          >
                            Save my details and create an account to follow
                            application status
                            <input
                              id="acceptRegister"
                              type="checkbox"
                              checked={formikApp.values.acceptRegister}
                              onChange={formikApp.handleChange}
                              className="w-4 h-4 text-white bg-white rounded border-gray-300 focus:ring-white focus:ring-2 "
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>

                        {showPassword ? (
                          <div>
                            <div>
                              <label
                                className="text-sm ff-cg--semibold"
                                htmlFor=""
                              >
                                Password
                              </label>
                              <input
                                className={
                                  "w-full bg-gray-100 placeholder:text-[#000000] p-[10px] focus:outline-none rounded-xl" +
                                  (formikApp.errors.name == "Required"
                                    ? " border border-red-500"
                                    : "")
                                }
                                type="password"
                                name="password"
                                value={formikApp.values.password}
                                onChange={(e: React.ChangeEvent<any>) => {
                                  formikApp.setFieldValue(
                                    "password",
                                    e.target.value
                                  );
                                }}
                                placeholder="*******"
                              />
                            </div>
                            <StrongPassword
                              value={formikApp.values.password}
                              validate={
                                formikApp.errors.password == "Required" &&
                                formikApp.values.password === ""
                              }
                              callback={(event: boolean) =>
                                handlePassword(event)
                              }
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    <button
                      type="submit"
                      className={
                        "w-full relative flex items-center justify-center bg-[#fdbf38] min-h-[52px] px-[16px] rounded-2xl mt-[30px] mb-6 " +
                        (submitLoader ? "!bg-[#da1a32] justify-between" : "")
                      }
                    >
                      <span
                        className={
                          "ff-cg--semibold mr-[20px] " +
                          (submitLoader ? "text-white" : "")
                        }
                      >
                        {submitLoader ? "Applying" : "Apply Now"}
                      </span>
                      <Loader
                        open={submitLoader}
                        dimension={"8"}
                        min={"8"}
                        wrapClass={""}
                      />
                    </button>
                    <div className="flex  items-center">
                      <label
                        form="default-checkbox"
                        className="ml-4 checkbox-application"
                      >
                        <input
                            id="acceptTerms"
                            type="checkbox"
                            checked={formikApp.values.acceptTerms}
                            onChange={formikApp.handleChange}
                            className={
                              "w-4 h-4 text-white bg-white rounded border-gray-300 focus:ring-white focus:ring-2 input-safari"
                            }
                          />
                          <span className="checkmark checkmark-checking"></span>                        
                      </label>
                      <label htmlFor="
                      ">
                        <span
                          className={
                            formikApp.errors.acceptTerms == "Required"
                              ? "ff-cg--semibold text-red-500"
                              : ""
                          }
                        >
                          
                          By checking this box
                        </span>
                        , I consent to be contacted by {sponsor}, including by
                        email, phone or text, about my interest in furthering my
                        career with online programs. I also agree to the{" "}
                        <span className="text-red-600 font-medium">
                          {" "}
                          Terms of Use
                        </span>{" "}
                        and{" "}
                        <span className="text-red-600 font-medium">
                          Privacy Policy.
                        </span>{" "}
                      </label>
                      
                    </div>
                  </div>
                </form>
              </div>
            )
          : ""}
        {isLogIn ? (
          <div className="">
            <h3 className="text-[26px] lg:text-[30px] ff-cg--semibold text-center mb-0">
              Welcome back
            </h3>
            <div className="text-center mb-1">
              <p>
                <a
                  href="#"
                  className="ff-cg--semibold text-[#da1a32] ml-2 underline cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    changeSignIn();
                  }}
                >
                  Back to application
                </a>
              </p>
            </div>
            {/* <div className='flex items-center justify-center'>
                <div className="w-full lg:w-[80%] lg:flex items-center justify-center gap-2 lg:gap-4">
                  <button className="w-full flex items-center justify-center border border-[#222222] py-[14px] px-[16px] rounded-2xl mt-[20px]">
                    <AdjustmentsVerticalIcon className="h-6 w-6"/>
                    <span className="ff-cg--semibold ml-[20px]">Continue with Google</span>
                  </button>
                  <button className="w-full flex items-center justify-center border border-[#222222] py-[14px] px-[16px] rounded-2xl mt-[20px]">
                    <AdjustmentsVerticalIcon className="h-6 w-6"/>
                    <span className="ff-cg--semibold ml-[20px]">Continue with Faceboock</span>
                  </button>
                </div>
              </div> */}
            {/* <div className='flex items-center justify-center py-4'>
                <div className="w-full lg:w-[80%] flex items-center justify-center">
                  <div className='w-full flex items-center gap-3'>
                    <span className='border border-solid w-full'></span>
                    <p className='text-gray-300'>or</p>
                    <span className='border border-solid w-full'></span>
                  </div>
                </div>
              </div> */}
            <form
              onSubmit={formikLogin.handleSubmit}
              className="flex items-center justify-center mt-8"
            >
              <div className="w-full lg:w-[80%]">
                <div className="mb-6">
                  <label className="text-sm ff-cg--semibold" htmlFor="">
                    Email
                  </label>
                  <input
                    className={
                      "w-full bg-gray-100 placeholder:text-[#000000] p-[10px] focus:outline-none rounded-xl mt-2" +
                      (formikLogin.errors.username == "Required"
                        ? " border border-red-500"
                        : "")
                    }
                    name="username"
                    type="text"
                    value={formikLogin.values.username}
                    onChange={formikLogin.handleChange}
                    placeholder="Student@domain.com"
                  />
                  {formikLogin.errors.username == "Required" && (
                    <p className="ff-cg--semibold text-red-500 text-[14px] mt-2 flex items-center">
                      <ExclamationTriangleIcon className="w-4 h-4 mr-1" /> Email
                      is required
                    </p>
                  )}
                </div>
                <div>
                  <div className="flex items-center justify-between">
                    <label className="text-sm ff-cg--semibold" htmlFor="">
                      Password
                    </label>
                    <a
                      className="ff-cg--semibold text-[#da1a32] ml-2 underline"
                      href=""
                    >
                      Forgot Password?
                    </a>
                  </div>
                  <input
                    className={
                      "w-full bg-gray-100 placeholder:text-[#000000] p-[10px] focus:outline-none rounded-xl mt-2" +
                      (formikLogin.errors.password == "Required"
                        ? " border border-red-500"
                        : "")
                    }
                    name="password"
                    type="password"
                    value={formikLogin.values.password}
                    onChange={formikLogin.handleChange}
                    placeholder="*******"
                  />
                  {formikLogin.errors.password == "Required" && (
                    <p className="ff-cg--semibold text-red-500 text-[14px] mt-2 flex items-center">
                      <ExclamationTriangleIcon className="w-4 h-4 mr-1" />{" "}
                      Password is required
                    </p>
                  )}
                </div>
                <button
                  type="submit"
                  className={
                    "w-full flex items-center justify-center bg-[#fdbf38] min-h-[52px] px-[16px] rounded-2xl mt-[30px] " +
                    (submitLoader ? "!bg-[#da1a32] justify-between" : "")
                  }
                >
                  <span
                    className={
                      "ff-cg--semibold mr-[20px] " +
                      (submitLoader ? "text-white" : "")
                    }
                  >
                    Sign In
                  </span>
                  <Loader
                    open={submitLoader}
                    dimension={"8"}
                    min={"8"}
                    wrapClass={""}
                  />
                </button>
              </div>
            </form>
            {errorLogin ? (
              <p className="text-orange-400 ff-cg--semibold text-center mt-4">
                There's a problem with your user
              </p>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        <Loader
          open={loader}
          dimension={"30"}
          min={"104"}
          wrapClass={
            "absolute bg-white w-full h-full flex items-center justify-center top-0 left-0"
          }
        />
      </div>
    </div>
  );
};

export default ModalApplication;
