import React from "react";
import { Link } from "gatsby";
import {
  ClockIcon,
  ComputerDesktopIcon,
  ShoppingCartIcon,
  RectangleStackIcon,
  PencilSquareIcon,
  Bars3BottomLeftIcon,
} from "@heroicons/react/24/outline";
import { Loader } from "../Loader/Loader";

export const Banner = (props: any) => {
  const course = props.values.courseObj;
  const sponsor = course?.sponsor;
  const children = course?.children;
  const modules = course?.modules;
  const sections = course?.sections;

  const { allowed, isAdmin, submitLoader } = props.values;
  const { addToCart, openApplication } = props.functions;
  const arrSponrs = ["MindEdge", "SHRM", "ASCM"];

  return (
    <section className="relative">
      <div
        className={`lg:mb-[40px] px-[15px] ${
          arrSponrs.indexOf(sponsor?.title) > -1
            ? "container mx-auto pt-[40px] lg:pt-[40px] lg:pb-0"
            : "container mx-auto pt-[40px] lg:pt-[110px]"
        }`}
      >
        <div
          className={`flex-col pt-[40px] gap-4 lg:gap-10 lg:flex-row flex items-start justify-between w-full ${
            arrSponrs.indexOf(sponsor?.title) > -1
              ? "px-[30px] min-h-[390px] relative rounded-2xl overflow-hidden"
              : "lg:items-center"
          }`}
        >
          <div className={"absolute top-0 left-0 w-full h-full"}>
            <div className="before:bg-black before:absolute before:h-full before:w-full before:top-0 before:bottom-0 before:left-0 before:right-0 before:opacity-50"></div>
            <div className={"w-full  overflow-hidden h-full"}>
              <img
                className={"w-full object-cover h-full "}
                src={course?.imgUrl}
                alt={course?.title}
              />
            </div>
          </div>
          <div
            className={"order-2 lg:order-1 w-full lg:w-[60%] flex items-center"}
          >
            <div className={"w-full relative"}>
              <h2 className="ff-cg--semibold text-white text-[34px] lg:text-[50px] leading-none mb-[10px]">
                {course?.title}
              </h2>
              <p className="text-white text-[16px] lg:text-[30px] mb-[20px] lg:mb-[50px]">
                {course?.description}
              </p>
              <div className="flex items-center flex-wrap mb-[60px] lg:mb-0">
                <span className="flex items-center text-white border border-white rounded-full pl-[3px] pr-[10px] mr-[10px] mb-[10px]">
                  <ComputerDesktopIcon className="h-4 w-4 mr-[6px]" />
                  <span className="ff-cg--semibold text-[12px]">Course</span>
                </span>
                <span className="flex items-center text-white border border-white rounded-full pl-[3px] pr-[10px] mr-[10px] mb-[10px]">
                  <ClockIcon className="h-4 w-4 mr-[6px]" />
                  <span className="ff-cg--semibold text-[12px]">
                    {course?.duration}
                  </span>
                </span>
                {!sections ? (
                  <span className="flex items-center text-white border border-white rounded-full pl-[3px] pr-[10px] mr-[10px] mb-[10px]">
                    <Bars3BottomLeftIcon className="h-4 w-4 mr-[6px]" />
                    <span className="ff-cg--semibold text-[12px]">
                      Available Dates
                    </span>
                  </span>
                ) : (
                  ""
                )}
                {sponsor?.title === "MindEdge" ? (
                  <>
                    {children !== null && children?.length ? (
                      <span className="flex items-center text-white border border-white rounded-full pl-[3px] pr-[10px] mr-[10px] mb-[10px]">
                        <RectangleStackIcon className="h-4 w-4 mr-[6px]" />
                        <span className="ff-cg--semibold text-[12px]">
                          {children.length} Courses
                        </span>
                      </span>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <>
                    {modules && modules.length ? (
                      <span className="flex items-center text-white border border-white rounded-full pl-[3px] pr-[10px] mr-[10px] mb-[10px]">
                        <RectangleStackIcon className="h-4 w-4 mr-[6px]" />
                        <span className="ff-cg--semibold text-[12px]">
                          {modules.length} Courses
                        </span>
                      </span>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
              <div className="flex items-center flex-col md:flex-row space-x-4 mt-[30px] mb-[60px]">
                <button className="w-full md:w-fit flex flex-col items-center justify-between border solid border-[#fdbf38] py-[5px] px-[16px] rounded-2xl mt-[20px]">
                  <span className="ff-cg--bold leading-none text-[28px] text-[#fdbf38]">
                    {Number(course?.price).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      currencyDisplay: "symbol",
                    })}
                  </span>
                  <span className="ff-cg--semibold text-[12px] text-white leading-none">
                    Price
                  </span>
                </button>
                {sections.length === 0 ? (
                  (sponsor?.title == "Springboard" ||
                    sponsor?.title == "MedCerts" ||
                    course?.uuid === process.env.SPECIAL_APPLICATION_COURSE ||
                    (sponsor?.title == "CertWise" &&
                      course?.uuid !==
                        "d7a532c8-cdc6-45e8-b411-32837c2b590d")) &&
                  !isAdmin ? (
                    <button
                      className="w-full md:w-fit flex items-center justify-between bg-[#fdbf38] py-[14px] px-[16px] rounded-2xl mt-[20px]"
                      onClick={() => openApplication()}
                    >
                      <span className="ff-cg--semibold mr-[20px]">
                        Apply Now{" "}
                      </span>
                      <PencilSquareIcon className="h-6 w-6" />
                    </button>
                  ) : (
                    <>
                      {allowed ? (
                        <button
                          className={
                            "w-full md:w-fit flex items-center justify-between bg-[#fdbf38] px-[16px] min-h-[52px] rounded-2xl mt-[20px] " +
                            (submitLoader ? "!bg-[#da1a32]" : "")
                          }
                          onClick={() => addToCart(course)}
                        >
                          <span className="ff-cg--semibold mr-[20px]">
                            {submitLoader ? "Adding to Cart" : "Add to Cart"}
                          </span>
                          {submitLoader ? (
                            <Loader
                              open={submitLoader}
                              dimension={"8"}
                              min={"8"}
                              wrapClass={""}
                            />
                          ) : (
                            <ShoppingCartIcon className="h-6 w-6" />
                          )}
                        </button>
                      ) : (
                        <>
                          {!isAdmin ? (
                            <button className="w-full md:w-fit flex items-center justify-between bg-[#fdbf38] py-[14px] px-[16px] rounded-2xl mt-[20px]">
                              <Link to={"/learning"} className="flex">
                                <span className="ff-cg--semibold mr-[20px]">
                                  Go to your Learning
                                </span>
                                <ShoppingCartIcon className="h-6 w-6" />
                              </Link>
                            </button>
                          ) : (
                            <button
                              className={
                                "w-full md:w-fit flex items-center justify-between bg-[#fdbf38] px-[16px] min-h-[52px] rounded-2xl mt-[20px] " +
                                (submitLoader ? "!bg-[#da1a32]" : "")
                              }
                              onClick={() => addToCart(course)}
                            >
                              <span className="ff-cg--semibold mr-[20px]">
                                {submitLoader
                                  ? "Adding to Cart"
                                  : "Add to Cart"}
                              </span>
                              {submitLoader ? (
                                <Loader
                                  open={submitLoader}
                                  dimension={"8"}
                                  min={"8"}
                                  wrapClass={""}
                                />
                              ) : (
                                <ShoppingCartIcon className="h-6 w-6" />
                              )}
                            </button>
                          )}
                        </>
                      )}
                    </>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div
            className={`order-1 lg:order-2 mb-6 lg:mb-0 relative lg:w-[40%] flex lg:relative lg:top-[70px] lg:items-center ${
              sponsor?.title === "MindEdge"
                ? "lg:justify-start"
                : "lg:justify-end"
            }`}
          >
            <div className="relative lg:absolute z-10 flex items-start lg:items-center lg:justify-center top-0 flex-col">
              <p className="ff-cg--semibold text-white">In Partnership With:</p>
              <p></p>
              <img
                className="w-auto object-cover h-12 mt-2"
                src={sponsor?.imgUrl}
                alt={sponsor?.name}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
