import * as React from "react";
import { Link } from "gatsby";
import { RectangleStackIcon } from "@heroicons/react/24/outline";

export const Parent = (props) => {
  const { parent } = props;
  return (
    <section className="container px-[15px] mx-auto pt-[40px] lg:pt-[60px] pb-[20px]">
      <div className="bg-[#da1a32] text-center rounded-2xl p-[30px]">
        <div className="lg:w-[60%] mx-auto">
          <h3 className="text-[20px] lg:text-[40px] ff-cg--semibold text-white">
            Part of {parent.title}
          </h3>
          <p className="ff-cg--light lg:text-[30px] text-white">
            Completing this course will count towards your learning in the{" "}
            {parent.title}
          </p>
          <button className="w-full lg:w-fit flex items-center justify-between bg-[#fdbf38] py-[14px] px-[16px] rounded-2xl mt-[20px] mx-auto">
            <Link
              to={`/course/${parent.slug}`}
              state={{ id: parent.title, course: parent }}
              className="flex"
            >
              <span className="ff-cg--semibold mr-[20px]">
                Go to Certificate
              </span>
              <RectangleStackIcon className="h-6 w-6" />
            </Link>
          </button>
        </div>
      </div>
    </section>
  );
};
