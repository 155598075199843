import React, { useEffect } from "react";

export const Skill = (props) => {
  const skills = props.skills;
  return (
    <div className="lg:col-span-4 mb-[-40px]">
      {skills?.length ? (
        <div className="bg-[#da1a32] rounded-2xl p-[20px]">
          <p className="text-white ff-cg--semibold mb-[10px]">
            Relevant Skills
          </p>
          <div className="flex items-center flex-wrap">
            {skills.map((skill: any, index: number) => {
              return (
                <span
                  className="flex items-center text-white border border-white rounded-full pl-[10px] pr-[10px] mr-[10px] mb-[10px]"
                  key={index}
                >
                  <span className="ff-cg--semibold text-[12px]">
                    {skill.name}
                  </span>
                </span>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
