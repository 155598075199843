import React from "react";
import imgMedCerts from "../../images/medcerts.png";
import imgMindEdgefrom from "../../images/mindedge-learning.png";
import imgAscm from "../../images/ascm.png";
import imgShrm from "../../images/shrm.png";
import imgATD from "../../images/why-atd.png";

export const Why = (props: any) => {
  const mindEdge = (
    <section className="container bg-[#ffffff] px-[15px] mx-auto pt-[40px] lg:pt-[60px] pb-[20px]">
      <div className="col-span-12 mb-[20px] lg:mb-[60px]">
        <div className="flex flex-col md:flex-row relative lg:items-center lg:justify-between p-[15px] lg:px-[30px] lg:py-[40px]">
          <div className="mb-8 lg:mb-0 mr-[50px]">
            <img
              src={imgMindEdgefrom}
              alt=""
              className="m-auto lg:m-0 w-[300px] md:w-[600px] lg:w-[700px]"
            />
          </div>
          <div className="w-full">
            <h3 className="ff-cg--bold text-[30px] lg:text-[40px] leading-none mb-6 lg:mb-8">
              Why MindEdge Learning
            </h3>
            <p className="text-base text-[18px] lg:text-[24px] leading-normal mb-6 lg:mb-8">
              Founded in 1998 by Harvard and MIT educators, MindEdge offers top
              shelf online courses and certificates to help you reskill,
              upskill, and stay enriched with lifelong learning.
            </p>
            <p className="text-base text-[18px] lg:text-[24px] leading-normal">
              With more than 3 million satisfied learners and an average
              satisfaction rating of 96.5%, MindEdge cares about your learning
              journey and their ability to positively impact your life.
            </p>
          </div>
        </div>
      </div>
    </section>
  );

  const medCerts = (
    <section className="">
      <div className="container px-[15px] mx-auto">
        <div className="col-span-12 mb-[20px] lg:mb-[60px]">
          <div className="bg-[#ffffff] rounded-2xl shadow-lg lg:flex relative lg:items-center lg:justify-between p-[15px] lg:px-[30px] lg:py-[40px]">
            <div className="mb-8 lg:mb-0 mr-[50px]">
              <img
                src={imgMedCerts}
                alt="MedCerts"
                className="m-auto lg:m-0 w-[300px] lg:w-[700px]"
              />
            </div>
            <div className="w-full">
              <h3 className="ff-cg--bold text-[30px] lg:text-[40px] leading-none mb-6 lg:mb-8">
                Why MedCerts
              </h3>
              <p className="text-base text-[18px] lg:text-[24px] leading-normal mb-6 lg:mb-8">
                MedCerts provides opportunity for a better future with
                personalized, short-term online skills training in healthcare or
                IT certification.
              </p>
              <p className="text-base text-[18px] lg:text-[24px] leading-normal mb-6 lg:mb-8">
                With their all-inclusive programs, backed by a data-driven
                approach to outreach and support, they have helped over 35,000
                students gain credentials for new careers.
              </p>
              <p className="text-base text-[18px] lg:text-[24px] leading-normal">
                They are with you every step of the way to help keep you on
                track and celebrate your success.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const ascm = (
    <section className="container bg-[#ffffff] px-[15px] mx-auto pt-[40px] lg:pt-[60px] pb-[20px]">
      <div className="col-span-12 mb-[20px] lg:mb-[60px]">
        <div className="flex flex-col md:flex-row relative lg:items-center lg:justify-between p-[15px] lg:px-[30px] lg:py-[40px]">
          <div className="mb-8 lg:mb-0 mr-[50px]">
            <img
              src={imgAscm}
              alt="ascm"
              className="m-auto lg:m-0 w-[300px] md:w-[600px] lg:w-[700px]"
            />
          </div>
          <div className="w-full">
            <h3 className="ff-cg--bold text-[30px] lg:text-[40px] leading-none mb-6 lg:mb-8">
              Why ASCM
            </h3>
            <p className="text-base text-[18px] lg:text-[24px] leading-normal mb-6 lg:mb-8">
              The Association for Supply Chain Management (ASCM) is the global
              leader in supply chain organizational transformation, innovation
              and leadership.
            </p>
            <p className="text-base text-[18px] lg:text-[24px] leading-normal">
              As the largest non-profit association for supply chain, ASCM is an
              unbiased partner, connecting companies around the world to the
              newest thought leadership on all aspects of supply chain.
            </p>
          </div>
        </div>
      </div>
    </section>
  );

  const shrm = (
    <section className="container bg-[#ffffff] px-[15px] mx-auto pt-[40px] lg:pt-[60px] pb-[20px]">
      <div className="col-span-12 mb-[20px] lg:mb-[60px]">
        <div className="flex flex-col md:flex-row relative lg:items-center lg:justify-between p-[15px] lg:px-[30px] lg:py-[40px]">
          <div className="mb-8 lg:mb-0 mr-[50px]">
            <img
              src={imgShrm}
              alt=""
              className="m-auto lg:m-0 w-[300px] md:w-[600px] lg:w-[700px]"
            />
          </div>
          <div className="w-full">
            <h3 className="ff-cg--bold text-[30px] lg:text-[40px] leading-none mb-6 lg:mb-8">
              Why SHRM?
            </h3>
            <p className="text-base text-[18px] lg:text-[24px] leading-normal mb-6 lg:mb-8">
              SHRM, the Society for Human Resource Management, creates better
              workplaces where employers and employees thrive together. As the
              voice of all things work, workers and the workplace, SHRM is the
              foremost expert, convener and thought leader on issues impacting
              today’s evolving workplaces.
            </p>
            <p className="text-base text-[18px] lg:text-[24px] leading-normal">
              With 300,000+ HR and business executive members in 165 countries,
              SHRM impacts the lives of more than 115 million workers and
              families globally.
            </p>
          </div>
        </div>
      </div>
    </section>
  );

  const certWise = (
    <section className="">
      <div className="container px-[15px] mx-auto">
        <div className="col-span-12 mb-[20px] lg:mb-[60px]">
          <div className="bg-[#ffffff] rounded-2xl shadow-lg lg:flex relative lg:items-center lg:justify-between p-[15px] lg:px-[30px] lg:py-[40px]">
            <div className="w-full">
              <h3 className="ff-cg--bold text-[30px] lg:text-[40px] leading-none mb-6 lg:mb-8">
                Why CertWise
              </h3>
              <p className="text-base text-[18px] lg:text-[24px] leading-normal mb-6 lg:mb-8">
                CertWise® was introduced in 2015 as the consumer-facing brand of
                Holmes Corporation, focused solely on developing and delivering
                exceptional educational experiences to individuals seeking
                professional certifications worldwide.
              </p>
              <p className="text-base text-[18px] lg:text-[24px] leading-normal">
                CertWise course options offer complete preparation, expert
                support, and ultimate flexibility with everything you need in
                one convenient and expertly designed system.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const atdWhy = (
    <section className="container bg-[#ffffff] px-[15px] mx-auto pt-[40px] lg:pt-[60px] pb-[20px]">
      <div className="col-span-12 mb-[20px] lg:mb-[60px]">
        <div className="flex flex-col md:flex-row relative lg:items-center lg:justify-between p-[15px] lg:px-[30px] lg:py-[40px]">
          <div className="mb-8 lg:mb-0 mr-[50px]">
            <img
              src={imgATD}
              alt="ascm"
              className="m-auto lg:m-0 w-[300px] md:w-[600px] lg:w-[700px]"
            />
          </div>
          <div className="w-full">
            <h3 className="ff-cg--bold text-[30px] lg:text-[40px] leading-none mb-6 lg:mb-8">
              Why ATD
            </h3>
            <p className="text-base text-[18px] lg:text-[24px] leading-normal mb-6 lg:mb-8">
              Achieving the Dream, Inc. and UMGC are excellent partners to offer
              this kind of microcredential to support the professional
              development of community college leaders and grow crucial data and
              analytics competencies across the field.
            </p>
          </div>
        </div>
      </div>
    </section>
  );

  const config: any = {
    MindEdge: mindEdge,
    MedCerts: medCerts,
    ASCM: ascm,
    SHRM: shrm,
    CertWise: certWise,
    ATD: atdWhy,
  };

  return config[props.title] ?? "";
};
